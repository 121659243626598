<template>
  <div>
    <bread-cum v-bind:breadcrumbItems="['เซียน', 'รายงาน']" />

    <div class="card border-primary mb-3" style="width: 700px">
      <div class="card-header border-primary bg-primary text-white p-2 h6">
        ตัวเลือกการค้นหา
      </div>
      <div class="card-body">
        <div class="d-flex mb-2">
          <b-form-datepicker :date-format-options="{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          }" locale="th" v-model="fromDate" placeholder="เลือกวัน" label-no-date-selected="ยังไม่เลือก" today-button
            reset-button close-button label-close-button="ปิด" label-reset-button="รีเซ็ต" label-today="วันนี้"
            label-today-button="วันนี้"></b-form-datepicker>
          <span class="mx-2 my-auto">ถึง</span>
          <b-form-datepicker :date-format-options="{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          }" locale="th" v-model="toDate" placeholder="เลือกวัน" label-no-date-selected="ยังไม่เลือก" today-button
            reset-button close-button label-close-button="ปิด" label-reset-button="รีเซ็ต" label-today="วันนี้"
            label-today-button="วันนี้"></b-form-datepicker>
          <button class="btn btn-primary ml-2" @click="getSianReport">
            ค้นหา
          </button>
        </div>

        <div class="d-flex">
          <button v-for="(item, index) in searchDateButtons" :key="index" class="btn btn-sm btn-warning mr-2"
            @click="setDateRange(item.key)">
            {{ item.name }}
          </button>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <CDataTable :items="items" :fields="fields" :items-per-page-select="false" :items-per-page="500" :no-items-view="{
        noResults: 'ไม่พบข้อมูล',
        noItems: 'ไม่พบข้อมูล',
      }" :table-filter="true" border striped pagination dark hover size="sm"
        add-table-classes="w-100 text-wrap text-center">
        <template #username="{ item }">
          <td>
            <router-link :to="'/members/' + item.userId" target="_blank">
              {{ item.username }}
            </router-link>
          </td>
        </template>
      </CDataTable>
    </div>
  </div>
</template>

<style></style>

<script>
export default {
  data() {
    return {
      items: [],
      fields: [
        {
          key: "username",
          label: "ชื่อผู้ใช้",
        },
        {
          key: "commissionHuay",
          label: "ค่าคอมมิชชั่นหวย (%)",
        },
        {
          key: "commissionGame",
          label: "ค่าคอมมิชชั่นเกม (%)",
        },
        {
          key: "sumCustomer",
          label: "จำนวนลูกค้าทั้งหมด",
        },
        {
          key: "sumNewCustomer",
          label: "จำนวนที่สมัครใหม่",
        },
        {
          key: "sumDeposit",
          label: "ยอดฝากรวม",
        },
        {
          key: "sumWithdraw",
          label: "ยอดถอนรวม",
        },
        {
          key: "sumWinLoseHuay",
          label: "แพ้ชนะ(หวย)",
        },
        {
          key: "sumWinLoseGame",
          label: "แพ้ชนะ(เกม)",
        },
        {
          key: "sumCommissionHuay",
          label: "คอม(หวย)",
        },
        {
          key: "sumCommissionGame",
          label: "คอม(เกม)",
        },
      ],
      fromDate: this.$date().startOf("day").format("YYYY-MM-DDTHH:mm:ssZ"),
      toDate: this.$date().endOf("day").format("YYYY-MM-DDTHH:mm:ssZ"),
      searchDateButtons: [
        {
          name: "วันนี้",
          key: "TODAY",
        },
        {
          name: "เมื่อวาน",
          key: "YESTERDAY",
        },
        {
          name: "สัปดาห์นี้",
          key: "WEEK",
        },
        {
          name: "สัปดาห์ที่แล้ว",
          key: "LASTWEEK",
        },
        {
          name: "เดือนนี้",
          key: "MONTH",
        },
        {
          name: "เดือนที่แล้ว",
          key: "LASTMONTH",
        },
      ],
    }
  },
  mounted() {
    this.getSianReport()
  },
  methods: {
    setDateRange(key) {
      if (key == "TODAY") {
        this.fromDate = this.$date()
          .startOf("day")
          .format("YYYY-MM-DDTHH:mm:ssZ")
        this.toDate = this.$date().endOf("day").format("YYYY-MM-DDTHH:mm:ssZ")
      } else if (key == "YESTERDAY") {
        this.fromDate = this.$date()
          .add(-1, "day")
          .startOf("day")
          .format("YYYY-MM-DDTHH:mm:ssZ")
        this.toDate = this.$date()
          .add(-1, "day")
          .endOf("day")
          .format("YYYY-MM-DDTHH:mm:ssZ")
      } else if (key == "WEEK") {
        this.fromDate = this.$date()
          .startOf("week")
          .format("YYYY-MM-DDTHH:mm:ssZ")
        this.toDate = this.$date().endOf("week").format("YYYY-MM-DDTHH:mm:ssZ")
      } else if (key == "LASTWEEK") {
        this.fromDate = this.$date()
          .add(-1, "week")
          .startOf("week")
          .format("YYYY-MM-DDTHH:mm:ssZ")
        this.toDate = this.$date()
          .add(-1, "week")
          .endOf("week")
          .format("YYYY-MM-DDTHH:mm:ssZ")
      } else if (key == "MONTH") {
        this.fromDate = this.$date()
          .startOf("month")
          .format("YYYY-MM-DDTHH:mm:ssZ")
        this.toDate = this.$date().endOf("month").format("YYYY-MM-DDTHH:mm:ssZ")
      } else if (key == "LASTMONTH") {
        this.fromDate = this.$date()
          .add(-1, "month")
          .startOf("month")
          .format("YYYY-MM-DDTHH:mm:ssZ")
        this.toDate = this.$date()
          .add(-1, "month")
          .endOf("month")
          .format("YYYY-MM-DDTHH:mm:ssZ")
      }

      this.getSianReport()
    },
    async getSianReport() {
      this.swal.processing()

      try {
        const { data } = await this.axios({
          method: "get",
          url: "sian/report",
          params: {
            startTime: this.$date(this.fromDate)
              .startOf("day")
              .format("YYYY-MM-DDTHH:mm:ssZ"),
            endTime: this.$date(this.toDate)
              .endOf("day")
              .format("YYYY-MM-DDTHH:mm:ssZ"),
          },
        })

        data.data?.forEach((item) => {
          item._cellClasses = {
            sumDeposit: this.util.getClassAmount(item.sumDeposit),
            sumWithdraw: this.util.getClassAmount(item.sumWithdraw * -1),
            sumWinLoseHuay: this.util.getClassAmount(item.sumWinLoseHuay),
            sumWinLoseGame: this.util.getClassAmount(item.sumWinLoseGame),
            sumCommissionHuay: this.util.getClassAmount(item.sumCommissionHuay),
            sumCommissionGame: this.util.getClassAmount(item.sumCommissionGame),
          }

          item.sumDeposit = this.util.formatMoney(item.sumDeposit)
          item.sumWithdraw = this.util.formatMoney(item.sumWithdraw)
          item.sumWinLoseHuay = this.util.formatMoney(item.sumWinLoseHuay)
          item.sumWinLoseGame = this.util.formatMoney(item.sumWinLoseGame)
          item.sumCommissionHuay = this.util.formatMoney(item.sumCommissionHuay)
          item.sumCommissionGame = this.util.formatMoney(item.sumCommissionGame)
        })

        this.items = data.data

        this.swal.close()
      } catch (e) {
        console.log(e)
        this.swal.swalError()
      }
    },
  },
}
</script>
